import {OutboundLink} from "gatsby-plugin-google-analytics";
import {StaticImage} from "gatsby-plugin-image";
import React from "react";

import HeadTwo from "../components/HeadTwo/HeadTwo";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import StructuredData, {StructuredDataType} from "../components/StructuredData";
import RolesTabs from "../components/pages/partners/Rolestabs";

const feats = [
  {
    name: "Sellers",
    title: "Build brand presence and reach your customers!",
    link: "https://app.getmason.io/login",
    cta_text: "Check it out",
    color: "#ED9D00",
    desc: "If you are just starting on Amazon or an expert seller who is looking to build brand visibility, Mason is all you need to check today to create images that connect with the customers and showcase the brand story.",
    img: (
      <StaticImage
        src='https://media.kubric.io/api/assetlib/4d1aacc1-7466-4a98-8908-960863c8eed9.png'
        alt='placeholder'
        placeholder='blurred'
      />
    ),
  },
  {
    name: "Agencies",
    title: "Customize your store for clients’ needs!",
    link: "https://app.getmason.io/login",
    cta_text: "Get Started Now",
    color: "#FE6960",
    desc: "Want to optimize product listing on Amazon store based on clients’ needs? With Mason, create high-quality product images with custom requirements and push them on your Amazon store with a single click.",
    img: (
      <StaticImage
        src='https://media.kubric.io/api/assetlib/6337cc5a-af8d-44fc-962f-1d81ce6745fb.png'
        alt='placeholder'
        placeholder='blurred'
      />
    ),
  },
  {
    name: "FBA merchants",
    title: "Enhance shoppers’ experience and grow your business!",
    link: "https://app.getmason.io/login",
    cta_text: "Try it yourself",
    color: "#943462",
    desc: "Looking for exponential business growth on Amazon stores? Mason helps you to optimize and update your store products for enhancing the customer experience and handling high customer traffic",
    img: (
      <StaticImage
        src='https://media.kubric.io/api/assetlib/518ef428-4d08-4b66-a25c-cd62e63bd355.png'
        alt='placeholder'
        placeholder='blurred'
      />
    ),
  },
];

const AmazonIntegration = () => {
  return (
    <Layout>
      <Seo
        title='Store merchandising tool-kit for Amazon sellers'
        description='With Mason, you can launch custom growth apps in minutes. It’s a headless platform that brings design, data, and channels, together. In one, single place. Publish product images on your Amazon store with a single click.'
      />
      <StructuredData
        type={StructuredDataType.BREADCRUMB}
        data={{
          name: "Store merchandising tool-kit for Amazon sellers",
          items: [
            {
              name: "Store merchandising tool-kit for Amazon sellers",
              item: "https://getmason.io/amazon",
            },
          ],
        }}
      />
      <div className='int-hero mt-12 flex flex-col-reverse md:flex-row items-center'>
        <div className='hero-content text-center md:text-left px-4 md:px-0 w-full md:w-2/4'>
          <h1 className='my-4'>
            Store merchandising <br />
            tool-kit for Amazon sellers
          </h1>
          <p>
            Mason helps you create high-quality product images that showcase
            your brand story. Publish product images on your Amazon store with a
            single click.
          </p>
          <OutboundLink
            href='https://app.getmason.io/login'
            target='_blank'
            className='primary-cta inline-block my-6'
            rel='noopener noreferrer'
            id='get-mason-app'
          >
            Get Started Now
          </OutboundLink>
        </div>
        <div className='hero-display w-full md:w-2/4'>
          <StaticImage
            src='https://media.kubric.io/api/assetlib/7f25b70b-12da-4c14-b377-f2123f64a415.png'
            alt='hero img'
          />
        </div>
      </div>
      <div className='int-roles my-8'>
        <HeadTwo>
          <strong className='medium'>Made for You. Works for your Team</strong>
        </HeadTwo>
        <RolesTabs
          data={feats}
          type='B'
          cta_url='https://app.getmason.io/login'
        />
      </div>
      <div className='int-assert'>
        <HeadTwo>
          <strong className='medium'>
            Create powerful product images for your store
          </strong>
        </HeadTwo>
        <div className='assert-wrap flex flex-col md:flex-row items-center justify-between'>
          <div className='assert-display shadow-lg w-full md:w-5/12 mb-32 md:mb-0'>
            <StaticImage
              src='https://media.kubric.io/api/assetlib/0d117b51-4814-4bb4-b83a-fec82aaebeb7.png'
              alt='img'
            />
          </div>
          <div className='assert-content relative w-full md:w-5/12'>
            <h4 className='w-3/4 md:w-full text-center md:text-left mx-auto'>
              Create product images with a library of 2000+ design atoms having
              icons, stickers, text elements, backgrounds, and more.
            </h4>
            <StaticImage
              src='https://media.kubric.io/api/assetlib/1e8a4118-b373-4083-902e-83d1f056a753.png'
              alt='bg'
              className='bg-img'
            />
          </div>
        </div>
      </div>
      <div className='int-last flex flex-col items-center my-32'>
        <HeadTwo>
          <strong className='medium'>
            Ready to make your customer’s <b>shopping experience</b> memorable?
          </strong>
        </HeadTwo>
        <OutboundLink
          href='https://app.getmason.io/login'
          target='_blank'
          className='inline-block primary-cta my-6 mx-auto'
          rel='noopener noreferrer'
          id='get-mason-app'
        >
          Get Started Now
        </OutboundLink>
      </div>
    </Layout>
  );
};

export default AmazonIntegration;
